const currencies = {
    USD: {
        symbol: "$",
        name: "Dollar",
    },
    EUR: {
        symbol: "€",
        name: "Euro",
    },
    JPY: {
        symbol: "¥",
        name: "Japanese Yen/Chinese Yuan",
    },
    GBP: {
        symbol: "£",
        name: "British Pound Sterling",
    },
    INR: {
        symbol: "₹",
        name: "Indian Rupee",
    },
    KRW: {
        symbol: "₩",
        name: "South Korean Won",
    },
    RUB: {
        symbol: "₽",
        name: "Russian Ruble",
    },
    BDT: {
        symbol: "৳",
        name: "Bangladeshi Taka",
    },
    MYR: {
        symbol: "RM",
        name: "Malaysian Ringgit",
    },
    SGD: {
        symbol: "S$",
        name: "Singapore Dollar",
    },
    AED: {
        symbol: "AED",
        name: "UAE Dirham",
    },
    QAR: {
        symbol: "QR",
        name: "Qatari Riyal",
    },
    SAR: {
        symbol: "SR",
        name: "Saudi Riyal",
    },
};

export default currencies;